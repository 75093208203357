import React, { useCallback, useEffect, useState } from "react";
import ReactDataGrid from "../components/datagrid/index";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import "@inovua/reactdatagrid-community/index.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import RedirectAuth from "./RedirectAuth";
import { convertDateString } from "../utils";
import HubStats from "../components/HubStats";
import SideDrawer from "../components/SideDrawer";
import { connect } from "react-redux";
import { RESET } from "../redux/hubRedux";
import { SET_SELECTED_LOAD_ID } from "../redux/hubRedux";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import moment from "moment";

window.moment = moment;
const load_types = [
  {
    id: "FlokX-Load",
    label: "FlokX-Load",
  },
  {
    id: "Partner-Load",
    label: "Partner-Load",
  },
];

const filterValue = [
  { name: "load_id", operator: "eq", type: "number" },
  { name: "profit_loss", operator: "eq", type: "number" },
  { name: "load_type", operator: "eq", type: "select", value: null },
  { name: "agent", operator: "inlist", type: "select", value: null },
  { name: "date", operator: "afterOrOn", type: "date", value: null },
];
const defaultSortInfo = { name: "load_id", dir: -1 };

function HubDetailsPage(props) {
  const agentsObj = {};

  props.hub.hubData.forEach((row) => {
    if (!agentsObj.hasOwnProperty(row.agent)) agentsObj[row.agent] = 0;
  });

  const agents = Object.keys(agentsObj).map((key) => {
    return {
      id: key,
      label: key,
    };
  });

  const columns = [
    {
      name: "load_id",
      header: 'Load ID',
      type: "number",
      filterEditor: NumberFilter,
      filterEditorProps: {
        placeholder: "All",
      },
    },
    {
      name: "agent",
      header: 'Agent',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: agents,
        multiple: true,
        wrapMultiple: false
      },
      type: "string",
    },
    {
      name: "buyer_payment_pending",
      header: 'Pending buyer payment',
      type: "number",
      render: ({ value }) => (
        <div className="table-cell">
          {value ? parseInt(Math.round(value)).toLocaleString("en-IN") : value}
        </div>
      ),
    },
    {
      name: "sale",
      header: 'Sale',
      render: ({ value }) => (
        <div className="table-cell">
          {value ? parseInt(Math.round(value)).toLocaleString("en-IN") : value}
        </div>
      ),
    },
    {
      name: "profit_loss",
      header: 'Profit/Loss',
      type: "number",
      filterEditor: NumberFilter,
      filterEditorProps: {
        placeholder: "All",
      },
      render: ({ value }) => (
        <div className="table-cell">
          {value ? parseInt(Math.round(value)).toLocaleString("en-IN") : value}
        </div>
      ),
    },
    {
      name: "profit_deposited",
      header: 'Profit Deposited to Bank',
      render: ({ value }) => (
        <div className="table-cell">
          {value ? parseInt(Math.round(value)).toLocaleString("en-IN") : value}
        </div>
      ),
    },
    {
      name: "partner_payment_pending",
      header: 'Pending Partner Payments',
      render: ({ value }) => (
        <div className="table-cell">
          {value ? parseInt(Math.round(value)).toLocaleString("en-IN") : value}
        </div>
      ),
    },
    {
      name: "load_type",
      header: 'Load Type',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: load_types,
      },
      render: ({ value }) => <div className="table-cell">{value}</div>,
    },
    {
      name: "date",
      header: 'Sale Date',
      dateFormat: "DD-MM-YYYY",
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          placeholder: "Sale after...",
        };
      },
      render: ({ value, cellProps: { dateFormat } }) => (
        <div className="table-cell">
          {moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").format(dateFormat)}
        </div>
      ),
    },
    {
      name: "days_taken_for_payment",
      header: 'Days taken for Payment',
      render: ({ value }) => <div className="table-cell">{value}</div>,
    },
    {
      name: "stock",
      header: 'Stock',
      render: ({ value }) => <div className="table-cell">{value}</div>,
    },
  ];

  const rowStyle = ({ data }) => {
    var styleObj = {}
    if (data['stock'] > 0) {
      styleObj = {
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
        color: 'black',
      }
    }
    else if (data['profit_deposited'] > 0) {
      styleObj = {
        backgroundColor: 'rgba(0, 171, 85, 0.2)',
        color: 'black',
      }
    }
    else if (data['rating']) {
      styleObj = {
        backgroundColor: 'rgba(220,20,60, 0.15)',
        color: 'black',
      }
    }
    return styleObj
  }

  const navigate = useNavigate();
  const onRenderRow = useCallback((rowProps) => {
    const { onClick } = rowProps;

    rowProps.onClick = (event) => {
      props.dispatch({
        type: SET_SELECTED_LOAD_ID,
        payload: rowProps.data.load_id,
      });

      navigate("/load");
      if (onClick) {
        onClick(event);
      }
    };
  }, []);

  const handleBack = () => {
    props.dispatch({ type: RESET });
    navigate("/");
  };

  useEffect(() => {
    if (props.hub.selectedHub === "") navigate("/");
  }, []);

  const summaryReducer = {
    initialValue: {
      profit_loss: 0,
      profit_deposited: 0,
      buyer_payment_pending: 0,
      pending_partner_payment: 0,
      sale: 0
    },
    reducer: (accumulator, item) => {
      if (item.profit_loss > 0)
        accumulator.profit_loss =
          accumulator.profit_loss + (item.profit_loss || 0);

      accumulator.profit_deposited =
        accumulator.profit_deposited + (item.profit_deposited || 0);

      accumulator.buyer_payment_pending =
        accumulator.buyer_payment_pending + (item.buyer_payment_pending || 0);

      accumulator.pending_partner_payment =
        accumulator.pending_partner_payment +
        (item.pending_partner_payment || 0);

      accumulator.sale =
        accumulator.sale +
        (item.sale || 0);

      return accumulator;
    },
  };

  const footerRows = [
    {
      render: {
        load_id: <b>No Total</b>,
        agent: <b>No Total</b>,
        load_type: <b>No Total</b>,
        date: <b>No Total</b>,
        days_taken_for_payment: <b>No Total</b>,
        profit_loss: ({ summary }) => (
          <div>
            Total:{" "}
            <b>{parseInt(summary.profit_loss).toLocaleString("en-IN")}</b>
          </div>
        ),
        profit_deposited: ({ summary }) => (
          <div>
            Total:{" "}
            <b>{parseInt(summary.profit_deposited).toLocaleString("en-IN")}</b>
          </div>
        ),
        buyer_payment_pending: ({ summary }) => (
          <div>
            Total:{" "}
            <b>
              {parseInt(summary.buyer_payment_pending).toLocaleString("en-IN")}
            </b>
          </div>
        ),
        pending_partner_payment: ({ summary }) => (
          <div>
            Total:{" "}
            <b>
              {parseInt(summary.pending_partner_payment).toLocaleString(
                "en-IN"
              )}
            </b>
          </div>
        ),
        sale: ({ summary }) => (
          <div>
            Total:{" "}
            <b>
              {parseInt(summary.sale).toLocaleString(
                "en-IN"
              )}
            </b>
          </div>
        ),
      },
    },
  ];

  return (
    <RedirectAuth>
      <SideDrawer />
      <div className="nav">
        <BiArrowBack className="back" onClick={handleBack} />
        <div className="headings-title">
          {" "}
          {props.hub.selectedHub} - Collection
        </div>
      </div>
      <HubStats mandi={props.hub.selectedHub} />
      <ReactDataGrid
        rowIndexColumn
        columns={columns}
        dataSource={props.hub.hubData}
        defaultFilterValue={filterValue}
        defaultSortInfo={defaultSortInfo}
        onRenderRow={onRenderRow}
        loading={props.hub.loading}
        summaryReducer={summaryReducer}
        rowStyle={rowStyle}
        footerRows={footerRows}
        style={{
          height: "70vh",
          margin: "20px",
          marginTop: "10px",
        }}
        showColumnMenuTool={false}
      />
    </RedirectAuth>
  );
}

const mapStateToProps = (state) => {
  return {
    hub: state.hub,
  };
};

export default connect(mapStateToProps)(HubDetailsPage);
