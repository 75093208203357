import { useEffect, useState } from "react";
import HubDetailsPage from "./pages/HubDetailsPage";
import LoadDetails from "./pages/LoadDetailsPage";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { auth } from "./config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getAnalytics, setUserId, logEvent } from "firebase/analytics";
import { connect } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux";
import { GET_USER } from "./redux/userRedux";
import AuthPage from "./pages/AuthPage";
import Procurment from "./pages/ProcurmentPage";
import Sales from "./pages/SalesPage";
import BuyerPaymentPage from "./pages/BuyerPaymentPage";
import BuyersPage from "./pages/BuyersPage";
import BuyerDetailsPage from "./pages/BuyerDetailsPage";

function Intermediate(props) {
  const [user, setUser] = useState(-1);
  const [selectedHub, setSelectedHub] = useState("");

  const [loadId, setLoadId] = useState(null);

  useEffect(() => {
    let authListener = onAuthStateChanged(auth, (user) => {
      if (user) {
        const analytics = getAnalytics();
        console.log(user.uid);
        setUserId(analytics, user.uid);

        logEvent(analytics, "user_visit");
      }
      props.dispatch({ type: GET_USER, payload: { user } });
    });

    return () => {
      authListener();
    };
  }, []);

  if (user === -1) {
    /// show splash screen
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route
          exact
          path="/login"
          element={<AuthPage user={user} setUser={setUser} />}
        />
        <Route exact path="/procurment" element={<Procurment />} />
        <Route exact path="/payments" element={<BuyerPaymentPage />} />
        <Route exact path="/buyers" element={<BuyersPage />} />
        <Route exact path="/buyerDetails" element={<BuyerDetailsPage />} />
        <Route exact path="/sales" element={<Sales />} />
        <Route exact path="/hub" element={<HubDetailsPage />} />
        <Route exact path="/load" element={<LoadDetails />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const ReduxIntermediate = connect(mapStateToProps)(Intermediate);

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ReduxIntermediate />
      </PersistGate>
    </Provider>
  );
}
