import React, { useEffect, useState, useCallback } from "react";
import ReactDataGrid from "../components/datagrid/index";
import '../components/index.css'
import { connect } from "react-redux";
import RedirectAuth from "./RedirectAuth";
import HubStats from "../components/HubStats";
import SideDrawer from "../components/SideDrawer";
import { BiArrowBack } from "react-icons/bi";
import { RESET, SET_SELECTED_BUYER, SET_BUYERS_DATA } from "../redux/hubRedux";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { useNavigate } from "react-router-dom";
import { convertDateString, adjustValueToHub, convertStringToTimestamp, trimString } from "../utils";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import moment from "moment";

window.moment = moment;

let all_buyers_json = {}
let all_buyers = []

function BuyersPage(props) {

    const [data, setData] = useState([]);
    const [buyersData, setBuyersData] = useState([]);

    const navigate = useNavigate();
    useEffect(() => {
        if (props.hub.selectedHub === "") navigate("/");
    }, []);

    const defaultSortInfo = { name: "pending_amount", dir: -1 };
    const filterValue = [
        { name: "customer_name", operator: "inlist", type: "select", value: null },
        { name: "first_sale_date", operator: "afterOrOn", type: "date", value: null },
        { name: "last_buyer_payment_date", operator: "afterOrOn", type: "date", value: null },
        { name: "load_type", operator: "eq", type: "select", value: null },
        { name: "buyer_payment", operator: "gte", type: "number" },
    ];
    const handleBack = () => {
        props.dispatch({ type: RESET });
        navigate("/");
    };

    const agentsObj = {};

    props.hub.buyerPaymentData.forEach((row) => {
        if (!agentsObj.hasOwnProperty(row.customer_name))
            agentsObj[row.customer_name] = 0;
    });

    const onRenderRow = useCallback((rowProps) => {
        const { onClick } = rowProps;

        rowProps.onClick = (event) => {

            props.dispatch({ type: SET_SELECTED_BUYER, payload: rowProps.data.customer_name });

            navigate("/buyerDetails");
            if (onClick) {
                onClick(event);
            }
        };
    }, []);

    const columns = [
        {
            name: "customer_name",
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: "All",
                dataSource: all_buyers,
                multiple: true,
                wrapMultiple: false,
            },
            header: 'Buyer',
            type: "string",
            render: ({ value }) => <div className="table-cell">{value}</div>,
        },
        {
            name: "customer_number",
            header: 'Phone Number',
            render: ({ value }) => <div className="table-cell">{value}</div>,
        },
        {
            name: "pending_days",
            header: 'Avg Days Taken',
            type: 'number',
            filterEditor: NumberFilter,
            render: ({ value }) => (
                <div className="table-cell">
                    {
                        value !== null ?
                            // parseInt(Math.round(value)).toLocaleString("en-IN") :
                            value :
                            '-'
                    }
                </div>
            ),
        },
        {
            name: "pending_amount",
            type: 'number',
            header: 'Pending buyer payment',
            filterEditor: NumberFilter,
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
        {
            name: "buyer_payment",
            type: 'number',
            header: 'Buyer payment done',
            filterEditor: NumberFilter,
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
        {
            name: "last_buyer_payment_date",
            header: 'Last payment date',
            dateFormat: "DD-MM-YYYY",
            filterEditor: DateFilter,
            render: ({ value, cellProps: { dateFormat } }) => (
                <div className="table-cell">
                    {moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").isValid() ?
                        moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").format(dateFormat) :
                        '-'
                    }
                </div>
            ),
        },
        {
            name: "latest_sale_date",
            header: 'Latest sale date',
            dateFormat: "DD-MM-YYYY",
            filterEditor: DateFilter,
            render: ({ value, cellProps: { dateFormat } }) => (
                <div className="table-cell">
                    {moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").isValid() ?
                        moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").format(dateFormat) :
                        '-'
                    }
                </div>
            ),
        },
        {
            name: "first_sale_date",
            header: 'First sale date',
            dateFormat: "DD-MM-YYYY",
            filterEditor: DateFilter,
            render: ({ value, cellProps: { dateFormat } }) => (
                <div className="table-cell">
                    {moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").isValid() ?
                        moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").format(dateFormat) :
                        '-'
                    }
                </div>
            ),
        },
        {
            name: "sale_value",
            type: 'number',
            header: 'Sale Value',
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
        {
            name: "sale_count",
            type: 'number',
            header: 'Sales Count',
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
        {
            name: "quantity_goats",
            type: 'number',
            header: 'Goats Bought',
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
    ];

    const summaryReducer = {
        initialValue: {
            pending_amount: 0,
            buyer_payment: 0,
            sale_value: 0,
            sale_count: 0,
            goats_count: 0,
            pending_days: 0,
            customer_name: 0
        },
        reducer: (accumulator, item) => {
            accumulator.pending_amount =
                accumulator.pending_amount + (item.pending_amount || 0);
            accumulator.buyer_payment =
                accumulator.buyer_payment + (item.buyer_payment || 0);
            accumulator.sale_value =
                accumulator.sale_value + (item.sale_value || 0);
            accumulator.sale_count =
                accumulator.sale_count + (item.sale_count || 0);
            accumulator.quantity_goats =
                accumulator.quantity_goats +
                (item.quantity_goats || 0);
            accumulator.customer_name =
                accumulator.customer_name + 1
            if (item.pending_days !== null) {
                accumulator.pending_days =
                    accumulator.pending_days + (item.pending_days || 0);
            }
            return accumulator;
        },
        complete: (accumulator, arr) => {
            accumulator.pending_days = Math.round(accumulator.pending_days / arr.length)
            return accumulator
        }
    };

    const footerRows = [
        {
            render: {
                customer_name: ({ summary }) => (
                    <div>
                        Count:{" "}
                        <b>{parseInt(summary.customer_name).toLocaleString("en-IN")}</b>
                    </div>
                ),
                customer_number: <b>No Total</b>,
                first_sale_date: <b>No Total</b>,
                latest_sale_date: <b>No Total</b>,
                last_buyer_payment_date: <b>No Total</b>,
                pending_days: ({ summary }) => (
                    <div>
                        Avg:{" "}
                        <b>{parseInt(summary.pending_days).toLocaleString("en-IN")}</b>
                    </div>
                ),
                pending_amount: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>{parseInt(summary.pending_amount).toLocaleString("en-IN")}</b>
                    </div>
                ),
                buyer_payment: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>{parseInt(summary.buyer_payment).toLocaleString("en-IN")}</b>
                    </div>
                ),
                sale_value: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>
                            {parseInt(summary.sale_value).toLocaleString("en-IN")}
                        </b>
                    </div>
                ),
                sale_count: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>
                            {parseInt(summary.sale_count).toLocaleString(
                                "en-IN"
                            )}
                        </b>
                    </div>
                ),
                quantity_goats: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>
                            {parseInt(summary.quantity_goats).toLocaleString(
                                "en-IN"
                            )}
                        </b>
                    </div>
                ),
            },
        },
    ];

    useEffect(() => {

        let newObj = {}
        let final = []
        let buyers_sales_count = {}

        if (props.hub.saleData.length > 0 && Object.keys(props.hub.hubJSON).length > 0) {

            let jabi_count = 0

            for (let i = 0; i < props.hub.saleData.length; i++) {
                const obj = props.hub.saleData[i];
                const customer_name = trimString(obj.customer_name);
                const key = customer_name + '|' + obj.load_id;
                const agent = props.hub.hubJSON[obj.load_id].agent;

                // if(customer_name === 'Jabi Khan') {
                //     jabi_count += obj.sale
                //     console.log('***',obj.sale, jabi_count)
                // }

                if ((props.hub.selectedHub === 'Chengicherla' && obj.load_id >= 378) ||
                    (props.hub.selectedHub === 'Ziaguda' && (obj.load_id >= 329 && ((agent !== 'Shankar Seth' || agent === 'Srisailam Sait') || (obj.load_id === 394)))) ||
                    (props.hub.selectedHub === 'Anantapur' && ((agent === 'Yellappa' || (obj.load_id >= 238 && agent === 'Prathap')))) ||
                    props.hub.selectedHub === 'Bagewadi' || props.hub.selectedHub === 'Shahpur' || props.hub.selectedHub === 'Ajmer' ||
                    props.hub.selectedHub === 'Siddipet' || props.hub.selectedHub === 'Bangalore'
                ) {

                    let load_type = props.hub.hubJSON[obj.load_id].load_type;
                    let agentHere = props.hub.hubJSON[obj.load_id].agent

                    if (!buyers_sales_count.hasOwnProperty(customer_name)) {
                        buyers_sales_count[customer_name] = 1;
                    }
                    else {
                        buyers_sales_count[customer_name] += 1;
                    }

                    if (!newObj.hasOwnProperty(key)) {

                        newObj[key] = {
                            customer_name: customer_name,
                            customer_number: obj.customer_number,
                            load_id: obj.load_id,
                            hub: obj.hub,
                            mandi: obj.mandi,
                            sale: adjustValueToHub(obj.sale, props.hub.selectedHub, load_type, agentHere),
                            sale_transferred: obj.sale_transferred,
                            quantity_goats: obj.quantity_goats,
                            dates: [convertStringToTimestamp(obj.date)],
                            last_payment_dates: obj.last_payment_date !== '' ? [convertStringToTimestamp(obj.last_payment_date)] : [],
                            buyer_payment_dates: [],
                            buyer_payment: 0
                        };

                    } else {
                        const dates = newObj[key].dates;
                        const lastPaymentDates = newObj[key].last_payment_dates;
                        let date = obj.date;
                        let lastPaymentDate = obj.last_payment_date;

                        let i = 0;
                        while (i < dates.length && dates[i] < date) {
                            i++;
                        }
                        dates.splice(i, 0, date);

                        if (lastPaymentDate !== '') {
                            lastPaymentDate = convertStringToTimestamp(lastPaymentDate);
                            i = 0;
                            while (i < lastPaymentDates.length && lastPaymentDates[i] < lastPaymentDate) {
                                i++;
                            }
                            lastPaymentDates.splice(i, 0, lastPaymentDate);
                        }

                        newObj[key].sale += adjustValueToHub(obj.sale, props.hub.selectedHub, load_type, agentHere);
                        newObj[key].sale_transferred += obj.sale_transferred;
                        newObj[key].quantity_goats += obj.quantity_goats;
                    }
                }
            }

            for (let i = 0; i < props.hub.buyerPaymentData.length; i++) {
                const obj = props.hub.buyerPaymentData[i];
                const key = trimString(obj.customer_name) + '|' + obj.load_id;

                if (newObj.hasOwnProperty(key)) {

                    const dates = newObj[key].buyer_payment_dates;
                    const date = convertStringToTimestamp(obj.date);

                    let i = 0;
                    while (i < dates.length && dates[i] < date) {
                        i++;
                    }
                    dates.splice(i, 0, date);
                    newObj[key].buyer_payment += obj.buyer_payment;
                }
            }

            let newObjKeys = Object.keys(newObj);

            let buyersJSON = {}

            for (let i = 0; i < newObjKeys.length; i++) {

                const key = newObjKeys[i];
                const obj = newObj[key];
                const customer_name = trimString(obj.customer_name)


                let first_sale_date = obj.dates[0];

                let latest_sale_date = obj.dates[obj.dates.length - 1];

                let last_buyer_payment_date = ''
                if (obj['buyer_payment_dates'].length > 0) {
                    last_buyer_payment_date = obj['buyer_payment_dates'][obj['buyer_payment_dates'].length - 1]
                }
                else if (obj['last_payment_dates'].length > 0) {
                    last_buyer_payment_date = obj['last_payment_dates'][obj['last_payment_dates'].length - 1]
                }

                let pending_amount = obj.sale - obj.sale_transferred - obj.buyer_payment;

                let buyer_payment = obj.buyer_payment + obj.sale_transferred;

                let pending_days = null;
                if (pending_amount > 0) {
                    let endDate = new Date();
                    const startDate = new Date(first_sale_date)

                    const diffInMs = new Date(endDate) - new Date(startDate);
                    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                    pending_days = Math.floor(diffInDays);
                }
                else if (last_buyer_payment_date !== '') {
                    let endDate = new Date(last_buyer_payment_date);
                    const startDate = new Date(first_sale_date)

                    const diffInMs = new Date(endDate) - new Date(startDate);
                    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                    pending_days = Math.round(diffInDays);
                }

                let sale_value = obj.sale;

                let quantity_goats = obj.quantity_goats;

                let load_type = props.hub.hubJSON[obj.load_id]['load_type']
                let agent = props.hub.hubJSON[obj.load_id]['agent']

                if (!all_buyers_json[customer_name]) {
                    all_buyers_json[customer_name] = true
                    all_buyers.push({
                        "id": customer_name,
                        "label": customer_name
                    })
                }

                final.push({
                    customer_name: customer_name,
                    customer_number: obj.customer_number,
                    load_id: obj.load_id,
                    pending_amount: pending_amount,
                    pending_days: pending_days,
                    buyer_payment: buyer_payment,
                    first_sale_date: first_sale_date,
                    last_buyer_payment_date: last_buyer_payment_date,
                    sale_value: sale_value,
                    quantity_goats: quantity_goats,
                    load_type: load_type,
                    agent: agent
                })

                if (!buyersJSON.hasOwnProperty(customer_name)) {

                    buyersJSON[customer_name] = {
                        customer_name: customer_name,
                        customer_number: obj.customer_number,
                        pending_amount: pending_amount,
                        buyer_payment: buyer_payment,
                        sale_value: sale_value,
                        quantity_goats: quantity_goats,
                        pending_days: pending_days !== null ? pending_days : 0,
                        pending_days_total: pending_days !== null ? pending_days : 0,
                        pending_count: pending_days !== null ? 1 : 0,
                        first_sale_date: first_sale_date,
                        latest_sale_date: latest_sale_date,
                        last_buyer_payment_date: last_buyer_payment_date,
                        sale_count: buyers_sales_count[customer_name] ? buyers_sales_count[customer_name] : 0,
                    }
                }
                else {

                    buyersJSON[customer_name].pending_amount += pending_amount;
                    buyersJSON[customer_name].buyer_payment += buyer_payment;
                    buyersJSON[customer_name].sale_value += sale_value;
                    buyersJSON[customer_name].quantity_goats += quantity_goats;

                    if (pending_days !== null) {
                        buyersJSON[customer_name].pending_days_total += pending_days;
                        buyersJSON[customer_name].pending_count += 1;
                        buyersJSON[customer_name].pending_days = Math.round(buyersJSON[customer_name].pending_days_total / buyersJSON[customer_name].pending_count);
                    }

                    if (first_sale_date < buyersJSON[customer_name].first_sale_date) {
                        buyersJSON[customer_name].first_sale_date = first_sale_date;
                    }

                    if (latest_sale_date > buyersJSON[customer_name].latest_sale_date) {
                        buyersJSON[customer_name].latest_sale_date = latest_sale_date;
                    }

                    if (last_buyer_payment_date !== '' && (buyersJSON[customer_name].last_buyer_payment_date === '' || last_buyer_payment_date > buyersJSON[customer_name].last_buyer_payment_date)) {
                        buyersJSON[customer_name].last_buyer_payment_date = last_buyer_payment_date
                    }
                }
            }

            props.dispatch({
                type: SET_BUYERS_DATA,
                payload: final
            })
            setBuyersData(Object.values(buyersJSON));
        }

    }, [props.hub.buyerPaymentData, props.hub.saleData, props.hub.hubJSON])

    return (
        <RedirectAuth>
            <div>
                <SideDrawer />
                <div className="nav">
                    <BiArrowBack className="back" onClick={handleBack} />
                    <div className="headings-title">
                        {" "}
                        {props.hub.selectedHub} - Buyers
                    </div>
                </div>
                <HubStats />
                <ReactDataGrid
                    idProperty="customer_name"
                    rowIndexColumn={true}
                    columns={columns}
                    dataSource={buyersData}
                    loading={props.hub.loading}
                    defaultSortInfo={defaultSortInfo}
                    defaultFilterValue={filterValue}
                    // showColumnMenuTool={false}
                    style={{
                        height: "70vh",
                        margin: "20px",
                        // marginTop: "10px",
                    }}
                    reorderColumns={true}
                    onRenderRow={onRenderRow}
                    summaryReducer={summaryReducer}
                    footerRows={footerRows}
                    showColumnMenuTool={false}
                />
            </div>
        </RedirectAuth>
    );
}

const mapStateToProps = (state) => {
    return {
        hub: state.hub,
    };
};

export default connect(mapStateToProps)(BuyersPage);
