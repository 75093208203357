import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  convertToNumber,
  isDateGivenDaysAway,
  hasSameMonthAsCurrent,
  haveCommonElements,
  isDateGivenDaysAwayOlder,
  adjustValueToHub
} from "../utils";
import { hubs, hyderabadHubs, baseQueryUrl } from "../constants";
import RedirectAuth from "./RedirectAuth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../config/firebase";
import { connect } from "react-redux";
import {
  SET_HUB_STATS,
  SET_HUB_DATA,
  SET_HUB_JSON,
  SET_LOADING,
  SET_SELECTED_HUB,
  SET_PROCURMENT_DATA,
  SET_SALE_DATA,
  SET_BUYER_PAYMENT_DATA,
} from "../redux/hubRedux";
const lodash = require("lodash");
const moment = require('moment-timezone');

const aprilFirst = moment.tz('2023-04-01', 'Asia/Kolkata')
const augustFirst = moment.tz('2023-08-01', 'Asia/Kolkata')

function HubList(props) {
  const { user } = props;

  const navigate = useNavigate();

  // Handle User permissions
  const [currentHubs, setCurrentHubs] = useState(hubs);
  const [fetchingData, setFetchingData] = useState(true);
  const [userHub, setUserHub] = useState("");
  useEffect(() => {
    const db = getFirestore(app);

    async function getManagers() {
      try {
        setFetchingData(true);
        const docRef = doc(db, "config", "managers");
        const docSnap = await getDoc(docRef);
        const usersHubMapping = docSnap.data();
        let userHubs = [];
        if (
          usersHubMapping.core.hasOwnProperty(
            user.user.phoneNumber.substring(3)
          )
        )
          userHubs = ["core"];
        else if (
          usersHubMapping.managers.hasOwnProperty(
            user.user.phoneNumber.substring(3)
          )
        ) {
          userHubs =
            usersHubMapping.managers[user.user.phoneNumber.substring(3)].hub;
        }

        setUserHub(userHubs);
        setCurrentHubs(
          hubs.filter((hub) => {
            return (
              haveCommonElements(hub.ids, userHubs) || userHubs[0] === "core"
            );
          })
        );
        setFetchingData(false);
      } catch (error) {
        console.log(error);
      }
    }
    getManagers();
  }, []);

  // Fetch the data for profits from table 106
  const fetchSummary = async (param, paramString) => {
    const url = baseQueryUrl + "?investor=true";
    const res = await axios.get(url);

    let summary = {};
    let final = []

    let procurementSevenDaysTotal = 0

    res.data.data.forEach((row) => {

      if (row.load_id) {

        let obj = {
          ...row,
          flokx_payment_pending: convertToNumber(row['flokx_payment_pending']),
          buyer_payment_pending: convertToNumber(row['buyer_payment_pending']),
          agent_commission: convertToNumber(row['agent_commission']),
          transport: convertToNumber(row['transport']),
          flokx_commission: convertToNumber(row['flokx_commission']),
          buyer_payment: convertToNumber(row['buyer_payment']),
          partner_payment: convertToNumber(row['partner_payment']),
          cm2_profit: convertToNumber(row['cm2_profit']),
          actual_profit_loss: convertToNumber(row['actual_profit_loss']),
          stock: convertToNumber(row['stock']),
          expenditure: convertToNumber(row['expenditure']),
          partner_load_pending_collections: convertToNumber(row['partner_load_pending_collections']),
          goats_purchased: convertToNumber(row['goats_purchased']),
          goats_received: convertToNumber(row['goats_received']),
          goats_sold: convertToNumber(row['goats_sold']),
          profit_loss: convertToNumber(row['profit_loss']),
          flokx_pending_collection: convertToNumber(row['flokx_pending_collection']),
          total_goats_sold: convertToNumber(row['total_goats_sold']),
          total_cost: convertToNumber(row['total_cost']),
          purchase: convertToNumber(row['purchase']),
          load_recvd: convertToNumber(row['load_recvd']),
          total_sale: convertToNumber(row['total_sale']),
          purchase_transferred: convertToNumber(row['purchase_transferred']),
          agent_commission_transferred: convertToNumber(row['agent_commission_transferred']),
          transport_transferred: convertToNumber(row['transport_transferred']),
        }
        summary[row.load_id] = obj

        if (obj[paramString] === param) {

          let momentDate = moment.tz(obj.date, 'Asia/Kolkata')

          if (param === 'Chengicherla' && obj.load_id < 319) {
            return
          } /// filter chengicherla load id < 319

          if (param === 'Ziaguda' && (obj.agent === 'Srisailam Sait' || obj.agent === 'Shankar Seth' || momentDate.isBefore(aprilFirst))) {
            return
          } ///  filter ziaguda shankar, srisailam and loads < 1st august

          final.push({
            ...obj,
            purchase: obj.purchase + obj.load_recvd,
            quantity_goats: obj.goats_purchased + obj.goats_received
          })

          if (isDateGivenDaysAway(obj.date, 7)) {
            procurementSevenDaysTotal += obj.goats_received + obj.goats_purchased
          }
        }
      }
    })

    props.dispatch({ type: SET_PROCURMENT_DATA, payload: final })

    summary.procurementSevenDaysTotal = procurementSevenDaysTotal
    return summary
  }

  const fetchData = async (param, paramString, hub) => {
    const url = baseQueryUrl + "?load=all&sale=true&" + paramString + "=" + param;
    Promise.all([
      fetchSummary(param, paramString),
      axios.get(url),
    ]).then(([profits, response]) => {

      let sales = []
      let salesJSON = {}

      let buyerPayment = []
      let buyerPaymentJSON = {}

      let partnerPaymentJSON = {}
      let flokxCommissionJSON = {}

      let procurementSevenDaysTotal = profits.procurementSevenDaysTotal
      let collectionSevenDaysTotal = 0
      let salesSevenDaysTotal = 0
      let salesMonthTotal = 0

      response.data.data.forEach((element) => {

        if (element.load_id) {

          let obj = {
            date: element.date,
            hub: element.hub,
            load_type: element.load_type,
            agent: element.agent,
            load_id: element.load_id,
            mandi: element.mandi,
            customer_name: element.customer_name,
            customer_number: element.customer_number,
            entity: element.entity
          }

          if (obj.entity === "Sale") {

            obj.quantity_goats = convertToNumber(element.quantity_goats)
            obj.sale = convertToNumber(element.sale)
            obj.sale_transferred = convertToNumber(element.sale_transferred)
            obj.last_payment_date = element.last_payment_date

            if (!salesJSON[obj.load_id]) {
              salesJSON[obj.load_id] = lodash.cloneDeep(obj)
            }
            else {

              salesJSON[obj.load_id].quantity_goats += obj.quantity_goats
              salesJSON[obj.load_id].sale += obj.sale
              salesJSON[obj.load_id].sale_transferred += obj.sale_transferred

              let date = salesJSON[obj.load_id].date
              let last_payment_date = salesJSON[obj.load_id].last_payment_date

              if (new Date(obj.date) < new Date(date)) {
                salesJSON[obj.load_id].date = obj.date
              }

              if (obj.last_payment_date !== "") {
                if (last_payment_date === "") {
                  salesJSON[obj.load_id].last_payment_date = obj.last_payment_date
                }
                else if (new Date(obj.last_payment_date) > new Date(last_payment_date)) {
                  salesJSON[obj.load_id].last_payment_date = obj.last_payment_date
                }
              }
            }

            if (isDateGivenDaysAway(obj.date, 7)) {
              salesSevenDaysTotal += obj.sale
            }

            if (hasSameMonthAsCurrent(obj.date)) {
              salesMonthTotal += obj.sale
            }

            let momentDate = moment.tz(obj.date, 'Asia/Kolkata')
            if (obj.mandi === 'Chengicherla' && obj.load_id < 319) {
              return
            } /// filter chengicherla load id < 319

            if (obj.mandi === 'Ziaguda' && (obj.agent === 'Srisailam Sait' || obj.agent === 'Shankar Seth' || momentDate.isBefore(aprilFirst))) {
              return
            } ///  filter ziaguda shankar, srisailam and loads < 1st august

            sales.push(obj)
          }
          else if (obj.entity === "Buyer Payment") {

            obj.buyer_payment = convertToNumber(element.sale_transferred)

            if (!buyerPaymentJSON[obj.load_id]) {
              buyerPaymentJSON[obj.load_id] = lodash.cloneDeep(obj) /// else obj reference is stored and changes to the reference affect previously stored ones
            }
            else {

              buyerPaymentJSON[obj.load_id].buyer_payment += obj.buyer_payment

              let date = buyerPaymentJSON[obj.load_id].date
              if (new Date(obj.date) > new Date(date)) {
                buyerPaymentJSON[obj.load_id].date = obj.date
              }
            }

            if (isDateGivenDaysAway(obj.date, 7)) {
              collectionSevenDaysTotal += obj.buyer_payment
            }

            let momentDate = moment.tz(obj.date, 'Asia/Kolkata')
            if (obj.mandi === 'Chengicherla' && obj.load_id < 319) {
              return
            } /// filter chengicherla load id < 319

            if (obj.mandi === 'Ziaguda' && (obj.agent === 'Srisailam Sait' || obj.agent === 'Shankar Seth' || momentDate.isBefore(aprilFirst))) {
              return
            } ///  filter ziaguda shankar, srisailam and loads < 1st august

            buyerPayment.push(obj)
          }
          else if (obj.entity === "Partner Payment") {

            obj.partner_payment = convertToNumber(element.sale_transferred)
            if (!partnerPaymentJSON[obj.load_id]) {
              partnerPaymentJSON[obj.load_id] = lodash.cloneDeep(obj)
            }
            else {
              partnerPaymentJSON[obj.load_id].partner_payment += obj.partner_payment
            }
          }
          else if (obj.entity === "FlokX Commission") {

            obj.flokx_commission = convertToNumber(element.sale_transferred)
            if (!flokxCommissionJSON[obj.load_id]) {
              flokxCommissionJSON[obj.load_id] = lodash.cloneDeep(obj)
            }
            else {
              flokxCommissionJSON[obj.load_id].flokx_commission += obj.flokx_commission
            }
          }
        }
      })

      props.dispatch({ type: SET_SALE_DATA, payload: sales })
      props.dispatch({ type: SET_BUYER_PAYMENT_DATA, payload: buyerPayment })

      /////////////////////////////////////////////////

      let pendingBuyerPaymentTotal = 0
      let flokxWorkingCapitalTotal = 0
      let partnerWorkingCapitalTotal = 0

      let flokxWorkingCapitalTotalChengicherlaPre = 0
      let partnerWorkingCapitalTotalChengicherlaPre = 0
      let partnerWorkingCapitalTotalYadav = 0 /// for shankar and srisailam seth

      let final = []
      let finalJSON = {}

      Object.entries(salesJSON).forEach(([key, value]) => {

        let obj = lodash.cloneDeep(value)
        let load_id = obj.load_id

        obj.sale = adjustValueToHub(obj.sale, obj.hub, obj.load_type, obj.agent)

        /// partner payment
        obj.partner_payment = partnerPaymentJSON[load_id] ? partnerPaymentJSON[load_id].partner_payment : 0

        /// flokx commission
        obj.flokx_commission = flokxCommissionJSON[load_id] ? flokxCommissionJSON[load_id].flokx_commission : 0

        /// buyer payment and last payment date and partner payment pending
        if (obj.load_type === "FlokX-Load") {
          obj.buyer_payment = obj.sale_transferred
          let purchase_cost = profits[load_id] ? profits[load_id].purchase : 0
          let purchase_transferred = profits[load_id] ? profits[load_id].purchase_transferred : 0

          obj.partner_payment_pending = purchase_cost - purchase_transferred
        }
        else {
          obj.buyer_payment = buyerPaymentJSON[load_id] ? buyerPaymentJSON[load_id].buyer_payment : 0
          obj.last_payment_date = buyerPaymentJSON[load_id] ? buyerPaymentJSON[load_id].date : ""

          if (obj.hub === "Hyderabad") {
            obj.partner_payment_pending = 0.96 * obj.sale - obj.partner_payment
          }
          else {
            let load_received = profits[load_id] ? profits[load_id].load_recvd : 0
            obj.partner_payment_pending = load_received - obj.partner_payment
          }
        }

        /// buyer payment pending
        obj.buyer_payment_pending = obj.sale - obj.buyer_payment

        /// days taken
        let saleDate = new Date(obj.date)
        obj.days_taken_for_payment = null
        if (obj.buyer_payment_pending > 0) {
          let endDate = new Date();

          let diffInMs = new Date(endDate) - new Date(saleDate)
          let diffInDays = diffInMs / (1000 * 60 * 60 * 24)
          obj.days_taken_for_payment = Math.floor(diffInDays)
        }
        else if (obj.last_payment_date !== "") {
          let endDate = new Date(obj.last_payment_date)

          let diffInMs = new Date(endDate) - new Date(saleDate)
          let diffInDays = diffInMs / (1000 * 60 * 60 * 24)
          obj.days_taken_for_payment = Math.floor(diffInDays)
        }

        /// stock
        obj.stock = profits[load_id] ? profits[load_id].stock : 0

        /// profit loss
        obj.profit_loss = 0
        if (!obj.stock) {

          let totalCost = profits[load_id] ? profits[load_id].total_cost : 0
          let totalSale = profits[load_id] ? profits[load_id].total_sale : 0
          obj.profit_loss = adjustValueToHub(obj.sale, obj.hub, obj.load_type, obj.agent) - totalCost * (obj.sale / totalSale)

          if (obj.hub === "Hyderabad" && obj.load_type === "Partner-Load") {
            obj.profit_loss = obj.sale * 0.04
          }
        }

        /// profit deposited
        obj.profit_deposited = Math.max(obj.profit_loss - obj.buyer_payment_pending, obj.flokx_commission)

        let momentDate = moment.tz(obj.date, 'Asia/Kolkata')

        if (obj.load_type === "Partner-Load") {
          
          /// splitting partner working capital into pre 1st aug and 1st aug onwards
          if(obj.mandi === 'Chengicherla' && momentDate.isBefore(augustFirst)) {
            partnerWorkingCapitalTotalChengicherlaPre += obj.partner_payment + obj.flokx_commission - obj.buyer_payment
          }

          if(obj.mandi === 'Chengicherla' && !momentDate.isBefore(augustFirst)) {
            partnerWorkingCapitalTotal += obj.partner_payment + obj.flokx_commission - obj.buyer_payment
          }
          ///

          if (obj.mandi === 'Ziaguda' && (obj.agent === 'Srisailam Sait' || obj.agent === 'Shankar Seth')) {
            partnerWorkingCapitalTotalYadav += obj.partner_payment + obj.flokx_commission - obj.buyer_payment
          }

          if (obj.mandi === 'Ziaguda' && !(obj.agent === 'Srisailam Sait' || obj.agent === 'Shankar Seth')) {
            partnerWorkingCapitalTotal += obj.partner_payment + obj.flokx_commission - obj.buyer_payment
          }

          if(obj.mandi !== 'Chengicherla' && obj.mandi !== 'Ziaguda') {
            partnerWorkingCapitalTotal += obj.partner_payment + obj.flokx_commission - obj.buyer_payment
          }

        }
        else if (obj.load_id >= 187) {

          if (obj.load_id === 319 && obj.mandi !== 'Ziaguda') {
            return
          }

          let agent_commission_transferred = profits[load_id] ? profits[load_id].agent_commission_transferred : 0
          let purchase_transferred = profits[load_id] ? profits[load_id].purchase_transferred : 0
          let transport_transferred = profits[load_id] ? profits[load_id].transport_transferred : 0

          let agent_commission = profits[load_id] ? profits[load_id].agent_commission : 0
          let purchase = profits[load_id] ? profits[load_id].purchase : 0
          let transport = profits[load_id] ? profits[load_id].transport : 0
          let cm2_profit = obj.sale - agent_commission - purchase - transport

          if (obj.load_id === 319) {
            let sale_here = profits[load_id] ? profits[load_id].total_sale : 0
            cm2_profit = sale_here - agent_commission - purchase - transport
          }

          let purchased_quantity = profits[load_id] ? profits[load_id].goats_purchased : 0
          let sold_quantity = profits[load_id] ? profits[load_id].goats_sold : 0
          let stock = purchased_quantity - sold_quantity

          let workingCapitalHere = 0

          if (!stock) {
            workingCapitalHere = purchase_transferred + agent_commission_transferred + transport_transferred + cm2_profit - obj.buyer_payment;
          } else {
            workingCapitalHere = purchase_transferred + agent_commission_transferred + transport_transferred - obj.buyer_payment;
          }

          /// splitting flokx working capital into pre 1st aug and 1st aug onwards
          if(obj.mandi === 'Chengicherla' && momentDate.isBefore(augustFirst)) {
            flokxWorkingCapitalTotalChengicherlaPre += workingCapitalHere
          }

          if(obj.mandi === 'Chengicherla' && !momentDate.isBefore(augustFirst)) {
            flokxWorkingCapitalTotal += workingCapitalHere
          }
          ///

          if(obj.mandi !== 'Chengicherla') {
            flokxWorkingCapitalTotal += workingCapitalHere
          }
        }

        if (obj.mandi === 'Chengicherla' && obj.load_id < 319) {
          return
        } /// filter chengicherla load id < 319

        if (obj.mandi === 'Ziaguda' && (obj.agent === 'Srisailam Sait' || obj.agent === 'Shankar Seth' || momentDate.isBefore(aprilFirst))) {
          return
        } ///  filter ziaguda shankar, srisailam and loads < 1st august

        final.push(obj)
        finalJSON[obj.load_id] = obj

        pendingBuyerPaymentTotal += obj.buyer_payment_pending
      })

      const hubStats = {
        totalPendingPayments: pendingBuyerPaymentTotal,
        totalBuyerPaymentsWeek: collectionSevenDaysTotal,
        totalProcurmentWeek: procurementSevenDaysTotal,
        partnerWorkingCapital: partnerWorkingCapitalTotal,
        totalSalesWeek: salesSevenDaysTotal,
        totalSalesMonth: salesMonthTotal,
        totalFlokxWorkingCapital: flokxWorkingCapitalTotal,
        flokxWorkingCapitalTotalChengicherlaPre,
        partnerWorkingCapitalTotalChengicherlaPre,
        partnerWorkingCapitalTotalYadav,
      };

      props.dispatch({ type: SET_HUB_STATS, payload: hubStats })
      props.dispatch({ type: SET_HUB_DATA, payload: final })
      props.dispatch({ type: SET_HUB_JSON, payload: finalJSON })
      props.dispatch({ type: SET_LOADING, payload: false })
    });
  };

  const handleClick = (hub) => {
    if (hub.label === "Hyderabad") {
      setCurrentHubs(
        hyderabadHubs.filter(
          (hub) => haveCommonElements(userHub, hub.ids) || userHub[0] === "core"
        )
      );
    } else if (hub.label === "Ziaguda" || hub.label === "Chengicherla") {
      props.dispatch({ type: SET_SELECTED_HUB, payload: hub.label });
      fetchData(hub.label, "mandi", "Hyderabad");
      props.dispatch({ type: SET_LOADING, payload: true });
      navigate("/hub");
    } else {
      props.dispatch({ type: SET_SELECTED_HUB, payload: hub.label });
      fetchData(hub.label, "hub", hub.label);
      props.dispatch({ type: SET_LOADING, payload: true });
      navigate("/hub");
    }
  };

  return (
    <RedirectAuth>
      <div className="hubContainer">
        {fetchingData && "Loading..."}
        {!fetchingData && (
          <>
            {currentHubs.map((hub) => (
              <div
                key={hub.label}
                className="hub-item"
                onClick={() => {
                  handleClick(hub);
                }}
              >
                {hub.label}
              </div>
            ))}
          </>
        )}
      </div>
    </RedirectAuth>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(HubList);
