import { PURGE } from "redux-persist";

const INITIAL_STATE = {
  user: null,
};

export const GET_USER = "GET_USER";

export default function yiming(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER:
      return { ...state, user: action.payload.user };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
