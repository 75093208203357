/**
 * Copyright © INOVUA TRADING.
 *
 * This source code is licensed under the Commercial License found in the
 * LICENSE file in the root directory of this source tree.
 */
import useTree from './useTree';
export default {
    name: 'tree',
    hook: useTree,
    defaultProps: () => {
        return {};
    },
};
