
export const convertToNumber = (value) => {
  try {
    if (!value) {
      return 0;
    }
    return parseFloat(value)
  } catch (e) {
    return 0;
  }
};


export const trimString = (value) => {
  try {
    return value.trim();
  } catch (error) {
    return value
  }
}

export const adjustValueToHub = (value, hub, loadType, agent) => {

  let villageHubs = ['Ajmer', 'Anantapur', 'Bagewadi', 'Shahpur']
  let villageAgents = ['Honnappa', 'Mahanthesh', 'Yellappa', 'Prathap', 'Shahrukh']

  if (loadType === 'Partner-Load' && villageHubs.includes(hub) && villageAgents.includes(agent)) {
    return value / 1.02
  }
  return value

}

export const isDateGivenDaysAway = (dateString, days) => {
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const today = new Date();
  const year = parseInt(dateString.substr(0, 4), 10);
  const monthIndex = parseInt(dateString.substr(5, 2), 10) - 1;
  const day = parseInt(dateString.substr(8, 2), 10);
  const dateToCheck = new Date(year, monthIndex, day, 0, 0, 0);
  dateToCheck.setDate(dateToCheck.getDate() + 1);

  const differenceInMilliseconds =
    new Date(dateToCheck).getTime() -
    today.getTime() +
    today.getTimezoneOffset() * 60000;
  const differenceInDays = differenceInMilliseconds / millisecondsInADay;

  return Math.floor(Math.abs(differenceInDays)) <= days;
};

export const hasSameMonthAsCurrent = (dateString) => {
  const date = new Date(dateString);
  const currentDate = new Date();
  return date.getMonth() === currentDate.getMonth();
};

export const convertDateString = (dateString, dateFormat = "DD-MM-YYYY") => {

  if (!dateString) return dateString;

  const date = new Date(dateString);
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleString("en-IN", options);

  // Split the formatted date by "-" and rearrange the parts based on the provided dateFormat
  const [day, month, year] = formattedDate.split("/");
  const rearrangedDate = dateFormat
    .replace("DD", day)
    .replace("MM", month)
    .replace("YYYY", year);

  return rearrangedDate;
};

export const convertStringToTimestamp = (dateString) => {
  const dateObj = new Date(dateString);
  // dateObj.setHours(0, 0, 0, 0);
  return dateObj.getTime();
}

export const haveCommonElements = (array1, array2) => {
  // Check if any element in array2 is present in set1
  for (let i = 0; i < array2.length; i++) {
    if (array1.includes(array2[i])) {
      return true; // Common element found
    }
  }

  return false; // No common elements found
};

export const isDateGivenDaysAwayOlder = (date1, date2) => {
  if (date2 === "") return false;
  return date1 < date2;
};
