import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate, useLocation } from "react-router-dom";

function SideDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <GiHamburgerMenu className="hamburger" onClick={toggleDrawer} />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="sideMenu"
      >
        <div
          className={`switchButton  ${
            location.pathname === "/hub" ? "active" : ""
          }`}
          onClick={() => {
            navigate("/hub");
          }}
        >
          Show Collection
        </div>
        <div
          className={`switchButton  ${
            location.pathname === "/procurment" ? "active" : ""
          }`}
          onClick={() => {
            navigate("/procurment");
          }}
        >
          Show Procurment
        </div>
        <div
          className={`switchButton  ${
            location.pathname === "/sales" ? "active" : ""
          }`}
          onClick={() => {
            navigate("/sales");
          }}
        >
          Show Sales
        </div>
        <div
          className={`switchButton  ${
            location.pathname === "/buyers" ? "active" : ""
          }`}
          onClick={() => {
            navigate("/buyers");
          }}
        >
          Show Buyers
        </div>
        <div
          className={`switchButton  ${
            location.pathname === "/payments" ? "active" : ""
          }`}
          onClick={() => {
            navigate("/payments");
          }}
        >
          Show Buyer Payments
        </div>
      </Drawer>
    </>
  );
}

export default SideDrawer;
