import React, { useEffect } from "react";
import ReactDataGrid from "../components/datagrid/index";
import "@inovua/reactdatagrid-community/index.css";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { convertDateString } from "../utils";
import HubStats from "../components/HubStats";
import SideDrawer from "../components/SideDrawer";
import { connect } from "react-redux";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { RESET } from "../redux/hubRedux";
import RedirectAuth from "./RedirectAuth";

const columns = [
  {
    name: "load_id",
    header: 'Load ID',
    type: "number",
    filterEditor: NumberFilter,
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "customer_name",
    header: 'Buyer',
    type: "string",
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "customer_number",
    header: 'Phone Number',
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "date",
    header: 'Date',
    render: ({ value }) => (
      <div className="table-cell">{convertDateString(value)}</div>
    ),
  },
  {
    name: "sale",
    header: 'Amount',
    render: ({ value }) => (
      <div className="table-cell">
        {parseInt(Math.round(value)).toLocaleString("en-IN")}
      </div>
    ),
  },
  {
    name: "quantity_goats",
    header: 'Goats Bought',
    render: ({ value }) => (
      <div className="table-cell">
        {parseInt(Math.round(value)).toLocaleString("en-IN")}
      </div>
    ),
  },
];

function Sales(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.hub.selectedHub === "") navigate("/");
  }, []);

  const defaultSortInfo = { name: "load_id", dir: -1 };
  const filterValue = [{ name: "load_id", operator: "eq", type: "number" }];

  const handelBack = () => {
    props.dispatch({ type: RESET });
    navigate("/");
  };

  return (
    <RedirectAuth>
      <div>
        <SideDrawer />
        <div className="nav">
          <BiArrowBack className="back" onClick={handelBack} />
          <div className="headings-title"> {props.hub.selectedHub} - Sales</div>
        </div>
        <HubStats />

        <ReactDataGrid
          columns={columns}
          dataSource={props.hub.saleData}
          loading={props.hub.loading}
          defaultSortInfo={defaultSortInfo}
          defaultFilterValue={filterValue}
          style={{
            height: "70vh",
            margin: "20px",
            marginTop: "10px",
          }}
          showColumnMenuTool={false}
        />
      </div>
    </RedirectAuth>
  );
}

const mapStateToProps = (state) => {
  return {
    hub: state.hub,
  };
};

export default connect(mapStateToProps)(Sales);
