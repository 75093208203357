import React, { useEffect } from "react";
import ReactDataGrid from "../components/datagrid/index";
import "@inovua/reactdatagrid-community/index.css";
import { connect } from "react-redux";
import RedirectAuth from "./RedirectAuth";
import HubStats from "../components/HubStats";
import SideDrawer from "../components/SideDrawer";
import { BiArrowBack } from "react-icons/bi";
import { RESET } from "../redux/hubRedux";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { useNavigate } from "react-router-dom";
import { convertDateString } from "../utils";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import moment from "moment";

window.moment = moment;

function BuyerPaymentPage(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.hub.selectedHub === "") navigate("/");
  }, []);

  const defaultSortInfo = { name: "load_id", dir: -1 };
  const filterValue = [
    { name: "load_id", operator: "eq", type: "number" },
    { name: "date", operator: "afterOrOn", type: "date", value: null },
    { name: "buyer_name", operator: "eq", type: "select", value: null },
    { name: "buyer_payment", operator: "gte", type: "number" },
  ];
  const handelBack = () => {
    props.dispatch({ type: RESET });
    navigate("/");
  };

  const agentsObj = {};

  props.hub.buyerPaymentData.forEach((row) => {
    if (!agentsObj.hasOwnProperty(row.buyer_name))
      agentsObj[row.buyer_name] = 0;
  });

  const agents = Object.keys(agentsObj).map((key) => {
    return {
      id: key,
      label: key,
    };
  });

  const columns = [
    {
      name: "load_id",
      header: 'Load ID',
      type: "number",
      filterEditor: NumberFilter,
      render: ({ value }) => <div className="table-cell">{value}</div>,
    },
    {
      name: "buyer_name",
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: agents,
      },
      header: 'Buyer',
      type: "string",
      render: ({ value }) => <div className="table-cell">{value}</div>,
    },
    {
      name: "buyer_number",
      header: 'Phone Number',
      render: ({ value }) => <div className="table-cell">{value}</div>,
    },
    {
      name: "buyer_payment",
      header: 'Payment',
      filterEditor: NumberFilter,
      render: ({ value }) => (
        <div className="table-cell">
          {parseInt(Math.round(value)).toLocaleString("en-IN")}
        </div>
      ),
    },
    {
      name: "date",
      header: 'Date',
      dateFormat: "DD-MM-YYYY",
      filterEditor: DateFilter,
      render: ({ value, cellProps: { dateFormat } }) => (
        <div className="table-cell">
          {moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").format(dateFormat)}
        </div>
      ),
    },
    {
      name: "days_taken_for_payment",
      header: 'Days Taken',
      render: ({ value }) => (
        <div className="table-cell">
          {parseInt(Math.round(value)).toLocaleString("en-IN")}
        </div>
      ),
    },
  ];

  //   console.log(props.hub.saleDateMapping);

  const buyerPaymentsWithPendingDates = props.hub.buyerPaymentData.map(
    (payment) => {
      if (props.hub.saleDateMapping[payment.load_id]) {
        const endDate = new Date(payment.date);
        const startDate = new Date(
          props.hub.saleDateMapping[payment.load_id].date
        );

        const diffInMs = new Date(endDate) - new Date(startDate);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        payment.days_taken_for_payment = Math.floor(diffInDays);
        return payment;
      }
    }
  );

  console.log(buyerPaymentsWithPendingDates);

  return (
    <RedirectAuth>
      <div>
        <SideDrawer />
        <div className="nav">
          <BiArrowBack className="back" onClick={handelBack} />
          <div className="headings-title">
            {" "}
            {props.hub.selectedHub} - Buyer Payments
          </div>
        </div>
        <HubStats />
        <ReactDataGrid
          columns={columns}
          dataSource={buyerPaymentsWithPendingDates}
          loading={props.hub.loading}
          defaultSortInfo={defaultSortInfo}
          defaultFilterValue={filterValue}
          style={{
            height: "70vh",
            margin: "20px",
            marginTop: "10px",
          }}
          showColumnMenuTool={false}
        />
      </div>
    </RedirectAuth>
  );
}

const mapStateToProps = (state) => {
  return {
    hub: state.hub,
  };
};

export default connect(mapStateToProps)(BuyerPaymentPage);
