import React, { useEffect, useState } from "react";
import ReactDataGrid from "../components/datagrid/index";
import "@inovua/reactdatagrid-community/index.css";
import { connect } from "react-redux";
import RedirectAuth from "./RedirectAuth";
import HubStats from "../components/HubStats";
import SideDrawer from "../components/SideDrawer";
import { BiArrowBack } from "react-icons/bi";
import { RESET } from "../redux/hubRedux";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { useNavigate } from "react-router-dom";
import { convertDateString, } from "../utils";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import moment from "moment";

window.moment = moment;

let all_agents = {}

function BuyersPage(props) {

    const [data, setData] = useState([]);
    const buyer_name = props.hub.selectedBuyer;
    const buyersData = props.hub.buyersData

    const navigate = useNavigate();
    useEffect(() => {
        if (props.hub.selectedHub === "") navigate("/");

        let final = []
        for (let i = 0; i < buyersData.length; i++) {
            if (buyersData[i].customer_name === buyer_name) {
                final.push(buyersData[i])
                all_agents[buyersData[i].agent] = true
            }
        }
        setData(final);
    }, []);

    const defaultSortInfo = { name: "load_id", dir: -1 };
    const filterValue = [
        { name: "load_id", operator: "eq", type: "number" },
        { name: "first_sale_date", operator: "afterOrOn", type: "date", value: null },
        { name: "last_buyer_payment_date", operator: "afterOrOn", type: "date", value: null },
        { name: "load_type", operator: "eq", type: "select", value: null },
        { name: "agent", operator: "inlist", type: "select", value: null },
        { name: "buyer_payment", operator: "gte", type: "number" },
    ];
    const handleBack = () => {
        navigate("/buyers");
    };

    const agentsObj = {};

    props.hub.buyerPaymentData.forEach((row) => {
        if (!agentsObj.hasOwnProperty(row.buyer_name))
            agentsObj[row.buyer_name] = 0;
    });

    const agents = Object.keys(agentsObj).map((key) => {
        return {
            id: key,
            label: key,
        };
    });

    const columns = [
        {
            name: "customer_number",
            header: 'Phone Number',
            render: ({ value }) => <div className="table-cell">{value}</div>,
        },
        {
            name: "load_id",
            header: 'Load ID',
            type: "number",
            filterEditor: NumberFilter,
            render: ({ value }) => <div className="table-cell">{value}</div>,
        },
        {
            name: "load_type",
            header: 'Load type',
            filterEditor: SelectFilter,
            type: 'string',
            filterEditorProps: {
                placeholder: "All",
                dataSource: [
                    {
                        "id": 'FlokX-Load',
                        "label": 'FlokX-Load'
                    },
                    {
                        "id": 'Partner-Load',
                        "label": 'Partner-Load'
                    }
                ],
            },
            render: ({ value }) => <div className="table-cell">{value}</div>,
        },
        {
            name: "agent",
            header: 'Agent',
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: "All",
                dataSource: Object.keys(all_agents).map((key) => {
                    return {
                        id: key,
                        label: key,
                    };
                }),
                multiple: true,
                wrapMultiple: false,
            },
            render: ({ value }) => <div className="table-cell">{value}</div>,
        },
        {
            name: "pending_days",
            header: 'Days Taken',
            filterEditor: NumberFilter,
            render: ({ value }) => (
                <div className="table-cell">
                    {
                        value !== null ?
                            parseInt(Math.round(value)).toLocaleString("en-IN") :
                            '-'
                    }
                </div>
            ),
        },
        {
            name: "pending_amount",
            header: 'Pending buyer payment',
            filterEditor: NumberFilter,
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
        {
            name: "buyer_payment",
            header: 'Buyer payment done',
            filterEditor: NumberFilter,
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
        {
            name: "first_sale_date",
            header: 'Sale date',
            dateFormat: "DD-MM-YYYY",
            filterEditor: DateFilter,
            render: ({ value, cellProps: { dateFormat } }) => (
                <div className="table-cell">
                    {moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").isValid() ?
                        moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").format(dateFormat) :
                        '-'
                    }
                </div>
            ),
        },
        {
            name: "last_buyer_payment_date",
            header: 'Last payment date',
            dateFormat: "DD-MM-YYYY",
            filterEditor: DateFilter,
            render: ({ value, cellProps: { dateFormat } }) => (
                <div className="table-cell">
                    {moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").isValid() ?
                        moment(convertDateString(value, "MM-DD-YYYY"), "MM-DD-YYYY").format(dateFormat) :
                        '-'
                    }
                </div>
            ),
        },
        {
            name: "sale_value",
            header: 'Sale Value',
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
        {
            name: "quantity_goats",
            header: 'Goats Bought',
            render: ({ value }) => (
                <div className="table-cell">
                    {parseInt(Math.round(value)).toLocaleString("en-IN")}
                </div>
            ),
        },
    ];

    const summaryReducer = {
        initialValue: {
            pending_amount: 0,
            buyer_payment: 0,
            sale_value: 0,
            sale_count: 0,
            goats_sold: 0,
            pending_days: 0,
            buyer_number: 0
        },
        reducer: (accumulator, item) => {
            accumulator.pending_amount =
                accumulator.pending_amount + (item.pending_amount || 0);
            accumulator.buyer_payment =
                accumulator.buyer_payment + (item.buyer_payment || 0);
            accumulator.sale_value =
                accumulator.sale_value + (item.sale_value || 0);
            accumulator.sale_count =
                accumulator.sale_count + (item.sale_count || 0);
            accumulator.goats_sold =
                accumulator.goats_sold +
                (item.goats_sold || 0);
            accumulator.buyer_number =
                accumulator.buyer_number + 1
            if (item.pending_days !== null) {
                accumulator.pending_days =
                    accumulator.pending_days + (item.pending_days || 0);
            }
            return accumulator;
        },
        complete: (accumulator, arr) => {
            accumulator.pending_days = Math.round(accumulator.pending_days / arr.length)
            return accumulator
        }
    };

    const footerRows = [
        {
            render: {
                buyer_number: ({ summary }) => (
                    <div>
                        Count:{" "}
                        <b>{parseInt(summary.buyer_number).toLocaleString("en-IN")}</b>
                    </div>
                ),
                load_id: <b>No Total</b>,
                load_type: <b>No Total</b>,
                last_buyer_payment_date: <b>No Total</b>,
                agent: <b>No Total</b>,
                first_sale_date: <b>No Total</b>,
                pending_days: ({ summary }) => (
                    <div>
                        Avg:{" "}
                        <b>{parseInt(summary.pending_days).toLocaleString("en-IN")}</b>
                    </div>
                ),
                pending_amount: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>{parseInt(summary.pending_amount).toLocaleString("en-IN")}</b>
                    </div>
                ),
                buyer_payment: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>{parseInt(summary.buyer_payment).toLocaleString("en-IN")}</b>
                    </div>
                ),
                sale_value: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>
                            {parseInt(summary.sale_value).toLocaleString("en-IN")}
                        </b>
                    </div>
                ),
                sale_count: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>
                            {parseInt(summary.sale_count).toLocaleString(
                                "en-IN"
                            )}
                        </b>
                    </div>
                ),
                goats_sold: ({ summary }) => (
                    <div>
                        Total:{" "}
                        <b>
                            {parseInt(summary.goats_sold).toLocaleString(
                                "en-IN"
                            )}
                        </b>
                    </div>
                ),
            },
        },
    ];


    return (
        <RedirectAuth>
            <div>
                <SideDrawer />
                <div className="nav">
                    <BiArrowBack className="back" onClick={handleBack} />
                    <div className="headings-title">
                        {" "}
                        {props.hub.selectedBuyer}
                    </div>
                </div>
                <HubStats />
                <ReactDataGrid
                    columns={columns}
                    dataSource={data}
                    loading={props.hub.loading}
                    defaultSortInfo={defaultSortInfo}
                    defaultFilterValue={filterValue}
                    style={{
                        height: "70vh",
                        margin: "20px",
                        marginTop: "10px",
                    }}
                    summaryReducer={summaryReducer}
                    footerRows={footerRows}
                    reorderColumns={true}
                    showHeader={true}
                    showColumnMenuTool={false}
                />
            </div>
        </RedirectAuth>
    );
}

const mapStateToProps = (state) => {
    return {
        hub: state.hub,
    };
};

export default connect(mapStateToProps)(BuyersPage);
