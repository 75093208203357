/**
 * Copyright © INOVUA TRADING.
 *
 * This source code is licensed under the Commercial License found in the
 * LICENSE file in the root directory of this source tree.
 */
import useLockedRows from './useLockedRows';
export default {
    name: 'locked-rows',
    hook: useLockedRows,
    defaultProps: () => {
        return {};
    },
};
