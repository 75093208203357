import { useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import "@inovua/reactdatagrid-community/index.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { convertToNumber, convertDateString, adjustValueToHub } from "../utils";
import RedirectAuth from "./RedirectAuth";
import { connect } from "react-redux";
import { SET_SELECTED_LOAD_ID } from "../redux/hubRedux";
const lodash = require("lodash");

const columns = [
  {
    name: "customer_name",
    header: 'Buyer Name',
    type: "string",
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "customer_number",
    header: 'Buyer Phone Number',
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "buyer_payment_pending",
    header: 'Pending Buyer Payment',
    type: "number",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? Math.round(value).toLocaleString("en-IN") : value}
      </div>
    ),
  },
  {
    name: "sale",
    header: 'Sale Amount',
    render: ({ value }) => (
      <div className="table-cell">
        {value ? Math.round(value).toLocaleString("en-IN") : value}
      </div>
    ),
  },
  {
    name: "buyer_payment",
    header: 'Buyer Payment',
    render: ({ value }) => (
      <div className="table-cell">
        {value ? Math.round(value).toLocaleString("en-IN") : value}
      </div>
    ),
  },
  {
    name: "days_taken_for_payment",
    header: 'Days taken for Payment',
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "date",
    header: 'Sale Date',
    render: ({ value }) => (
      <div className="table-cell">{convertDateString(value)}</div>
    ),
  },
];

const rowStyle = ({ data }) => {
  var styleObj = {}
  if (data['stock'] > 0) {
    styleObj = {
      backgroundColor: 'rgba(255, 165, 0, 0.2)',
      color: 'black',
    }
  }
  else if (data['buyer_payment_pending'] < 1) {
    styleObj = {
      backgroundColor: 'rgba(0, 171, 85, 0.2)',
      color: 'black',
    }
  }
  else if (data['days_taken_for_payment'] >= 14) {
    styleObj = {
      backgroundColor: 'rgba(220,20,60, 0.15)',
      color: 'black',
    }
  }
  return styleObj
}

function LoadDetails(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.hub.selectedLoadId === null) navigate("/");
    if (Object.keys(props.hub.hubJSON).length > 0) {
      fetchHubData();
    }
  }, [props.hub.hubJSON]);

  const fetchHubData = () => {

    let res1Data = []
    let res2Data = []

    let salesData = lodash.cloneDeep(props.hub.saleData)
    let buyerPaymentData = lodash.cloneDeep(props.hub.buyerPaymentData)

    if (props.hub.selectedHub !== 'Ziaguda' && props.hub.selectedHub !== 'Chengicherla') {
      res1Data = buyerPaymentData.filter(
        (item) =>
          item.load_id === props.hub.selectedLoadId &&
          item.hub === props.hub.selectedHub
      );
      res2Data = salesData.filter(
        (item) =>
          item.load_id === props.hub.selectedLoadId &&
          item.hub === props.hub.selectedHub
      );
    }
    else {
      res1Data = buyerPaymentData.filter(
        (item) =>
          item.load_id === props.hub.selectedLoadId &&
          item.mandi === props.hub.selectedHub
      );
      res2Data = salesData.filter(
        (item) =>
          item.load_id === props.hub.selectedLoadId &&
          item.mandi === props.hub.selectedHub
      );
    }

    let buyerPaymentsSummary = {}
    for (let i = 0; i < res1Data.length; i++) {

      let buyer_payment = convertToNumber(res1Data[i].buyer_payment)
      let customer_name = res1Data[i].customer_name
      let date = res1Data[i].date

      if (customer_name && buyer_payment) {
        if (!buyerPaymentsSummary[customer_name]) {
          buyerPaymentsSummary[customer_name] = {
            buyer_payment: buyer_payment,
            last_payment_date: res1Data[i].date
          }
        }
        else {
          let last_payment_date = buyerPaymentsSummary[customer_name].last_payment_date
          if (new Date(date) > new Date(last_payment_date)) {
            last_payment_date = date
          }
          buyerPaymentsSummary[customer_name] = {
            buyer_payment: buyerPaymentsSummary[customer_name].buyer_payment + buyer_payment,
            last_payment_date: last_payment_date
          }
        }
      }
    }

    let finalRes = {}
    res2Data.forEach((item) => {

      let obj = {
        customer_name: item.customer_name,
        customer_number: item.customer_number,
        date: item.date,
        goats_sold: convertToNumber(item.goats_sold),
        hub: item.hub,
        last_payment_date: item.last_payment_date,
        load_id: item.load_id,
        mandi: item.mandi,
        sale: convertToNumber(item.sale),
        sale_transferred: convertToNumber(item.sale_transferred),
      }

      let sale = adjustValueToHub(
        convertToNumber(obj.sale),
        props.hub.selectedHub,
        props.hub.hubJSON[props.hub.selectedLoadId].load_type,
        props.hub.hubJSON[props.hub.selectedLoadId].agent)

      if (obj.customer_name !== "") {

        if (!finalRes[obj.customer_name]) {
          finalRes[obj.customer_name] = {
            ...obj,
            sale: sale,
          }
        }
        else {

          let last_payment_date = finalRes[obj.customer_name].last_payment_date
          if (obj.last_payment_date !== "") {
            if (last_payment_date !== "") {
              last_payment_date = new Date(obj.last_payment_date) > new Date(last_payment_date) ? obj.last_payment_date : last_payment_date
            }
            else {
              last_payment_date = obj.last_payment_date
            }
          }

          finalRes[obj.customer_name] = {
            ...obj,
            sale: finalRes[obj.customer_name].sale + sale,
            goats_sold: finalRes[obj.customer_name].goats_sold + obj.goats_sold,
            sale_transferred: finalRes[obj.customer_name].sale_transferred + obj.sale_transferred,
            date: new Date(finalRes[obj.customer_name].date) < new Date(obj.date) ? finalRes[obj.customer_name].date : obj.date,
            last_payment_date: last_payment_date
          }
        }
      }
    })

    const filturedResult = []

    Object.entries(finalRes).forEach(([key, value]) => {

      let obj = {
        ...value,
      }

      let load_type = props.hub.hubJSON[props.hub.selectedLoadId].load_type
      if (load_type === 'FlokX-Load') {
        obj.buyer_payment = value.sale_transferred
        obj.buyer_payment_pending = value.sale - value.sale_transferred
      }
      else {
        obj.buyer_payment = buyerPaymentsSummary[key] ? buyerPaymentsSummary[key].buyer_payment : 0
        obj.buyer_payment_pending = value.sale - obj.buyer_payment
        obj.last_payment_date = buyerPaymentsSummary[key] ? buyerPaymentsSummary[key].last_payment_date : ""
      }

      if (obj.buyer_payment_pending !== 0) {

        const endDate = new Date();
        const startDate = new Date(obj.date);

        const diffInMs = new Date(endDate) - new Date(startDate);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        obj.days_taken_for_payment = Math.floor(diffInDays);
      }
      else if (obj.last_payment_date !== "" && obj.date !== "") {
        const endDate = new Date(obj.last_payment_date);
        const startDate = new Date(obj.date);

        const diffInMs = new Date(endDate) - new Date(startDate);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        obj.days_taken_for_payment = Math.floor(diffInDays);
      }

      filturedResult.push(obj)
    })

    setData(filturedResult);
    setLoading(false);
  };

  const defaultSortInfo = { name: "date", dir: -1 };

  const handleBack = () => {
    props.dispatch({
      type: SET_SELECTED_LOAD_ID,
      payload: null,
    });
    navigate("/hub");
  };

  return (
    <RedirectAuth>
      <div className="nav">
        <BiArrowBack className="back" onClick={handleBack} />
        <div className="headings-title">Load Details for {props.hub.selectedLoadId}</div>
      </div>

      <ReactDataGrid
        columns={columns}
        dataSource={data}
        loading={loading}
        defaultSortInfo={defaultSortInfo}
        rowStyle={rowStyle}
        style={{
          height: "90vh",
          margin: "20px",
          marginTop: "10px",
        }}
        showColumnMenuTool={false}
      />
    </RedirectAuth>
  );
}

const mapStateToProps = (state) => {
  return {
    hub: state.hub,
  };
};

export default connect(mapStateToProps)(LoadDetails);
