import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

const Redirect = (props) => {
  const location = useLocation();

  if (!props.user.user) {
    return <Navigate to="/login" state={{ path: location.pathname }} />;
  }
  return props.children;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const RedirectAuth = connect(mapStateToProps)(Redirect);
export default RedirectAuth;
