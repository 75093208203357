import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCAIzIbLPieT6Arib8Ly1dqZmdS4P99QS0",
  authDomain: "flok-90c44.firebaseapp.com",
  projectId: "flok-90c44",
  storageBucket: "flok-90c44.appspot.com",
  messagingSenderId: "860910331942",
  appId: "1:860910331942:web:f883bc33330eecf55b5ee7",
  measurementId: "G-HHR03JCX52",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const analytics = getAnalytics(app);
export { app, auth, analytics };
