import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { connect } from "react-redux";

function HubStats(props) {
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const mandi = props.mandi

  return (
    <div
      className="collapsible"
      onClick={() => setIsDetailsShown(!isDetailsShown)}
    >
      <Collapsible trigger="➕" triggerWhenOpen="✖️">
        <div className="heading-container">
          <div>
            <div className="headings">
              Total Pending Buyer Payments:{" "}
              {parseInt(props.hub.hubStats.totalPendingPayments).toLocaleString(
                "en-IN"
              )}
            </div>
            <div className="headings">
              Total FlokX Working Capital:{" "}
              {parseInt(
                props.hub.hubStats.totalFlokxWorkingCapital
              ).toLocaleString("en-IN")}
            </div>
            {mandi === 'Chengicherla' &&
              <div className="headings">
                Total FlokX Working Capital before 1st Aug:{" "}
                {parseInt(
                  props.hub.hubStats.flokxWorkingCapitalTotalChengicherlaPre
                ).toLocaleString("en-IN")}
              </div>
            }
            <div className="headings">
              Total Partner Collection in Past 7 Days:{" "}
              {parseInt(
                props.hub.hubStats.totalBuyerPaymentsWeek
              ).toLocaleString("en-IN")}
            </div>
          </div>
          <div>
            <div className="headings">
              Total Goats Procured in Past 7 Days at Hub:{" "}
              {parseInt(props.hub.hubStats.totalProcurmentWeek).toLocaleString(
                "en-IN"
              )}
            </div>
            <div className="headings">
              Partner Load Working Capital:{" "}
              {parseInt(
                props.hub.hubStats.partnerWorkingCapital
              ).toLocaleString("en-IN")}
            </div>
            {
              mandi === 'Chengicherla' &&
              <div className="headings">
                Partner Load Working Capital before 1st Aug:{" "}
                {parseInt(
                  props.hub.hubStats.partnerWorkingCapitalTotalChengicherlaPre
                ).toLocaleString("en-IN")}
              </div>
            }
            {
              mandi === 'Ziaguda' &&
              <div className="headings">
                Partner Load Working Capital of Shankar and Srisailam:{" "}
                {parseInt(
                  props.hub.hubStats.partnerWorkingCapitalTotalYadav
                ).toLocaleString("en-IN")}
              </div>
            }
          </div>
          <div>
            <div className="headings">
              Total Sales in Past 7 Days:{" "}
              {parseInt(props.hub.hubStats.totalSalesWeek).toLocaleString(
                "en-IN"
              )}
            </div>
            <div className="headings">
              Total Sales this month:{" "}
              {parseInt(props.hub.hubStats.totalSalesMonth).toLocaleString(
                "en-IN"
              )}
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    hub: state.hub,
  };
};

export default connect(mapStateToProps)(HubStats);
