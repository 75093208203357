import { PURGE } from "redux-persist";

const INITIAL_STATE = {
  hubStats: {
    totalPendingPayments: 0,
    totalBuyerPaymentsWeek: 0,
    totalProcurmentWeek: 0,
    partnerWorkingCapital: 0,
    totalSalesWeek: 0,
    totalSalesMonth: 0,
    totalFlokxWorkingCapital: 0,
  },
  hubData: [],
  hubJSON: {},
  procurmentData: [],
  saleData: [],
  loading: false,
  selectedHub: "",
  buyerPaymentData: [],
  selectedLoadId: "",
  saleDateMapping: {},
  selectedBuyer: "",
  buyersData: []
};

export const SET_HUB_STATS = "SET_HUB_STATS";
export const SET_HUB_DATA = "SET_HUB_DATA";
export const SET_HUB_JSON = "SET_HUB_JSON";
export const SET_LOADING = "SET_LOADING";
export const SET_SELECTED_HUB = "SET_SELECTED_HUB";
export const SET_PROCURMENT_DATA = "SET_PROCURMENT_DATA";
export const SET_SALE_DATA = "SET_SALE_DATA";
export const RESET = "RESET";
export const SET_BUYER_PAYMENT_DATA = "SET_BUYER_PAYMENT_DATA";
export const SET_SELECTED_LOAD_ID = "SET_SELECTED_LOAD_ID";
export const SET_SALE_DATE_MAPPING = "SET_SALE_DATE_MAPPING";

export const SET_BUYERS_DATA = "SET_BUYERS_DATA";
export const SET_SELECTED_BUYER = "SET_SELECTED_BUYER";

export default function yiming(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_HUB_STATS:
      return {
        ...state,
        hubStats: action.payload,
      };
    case SET_HUB_DATA:
      return {
        ...state,
        hubData: action.payload,
      };
    case SET_HUB_JSON:
      return {
        ...state,
        hubJSON: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_SELECTED_HUB:
      return {
        ...state,
        selectedHub: action.payload,
      };
    case SET_PROCURMENT_DATA:
      return {
        ...state,
        procurmentData: action.payload,
      };
    case SET_SALE_DATA:
      return {
        ...state,
        saleData: action.payload,
      };
    case SET_BUYER_PAYMENT_DATA:
      return {
        ...state,
        buyerPaymentData: action.payload,
      };
    case SET_SELECTED_LOAD_ID:
      return {
        ...state,
        selectedLoadId: action.payload,
      };
    case SET_SALE_DATE_MAPPING:
      return {
        ...state,
        saleDateMapping: action.payload,
      };
    case SET_SELECTED_BUYER:
      return {
        ...state,
        selectedBuyer: action.payload,
      }
    case SET_BUYERS_DATA:
      return {
        ...state,
        buyersData: action.payload,
      }
    case RESET:
      return INITIAL_STATE;
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
