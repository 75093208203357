import React, { useState } from "react";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import "../index.css";

const setUpRecaptcha = (number) => {
  const recaptchaVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    {},
    auth
  );
  recaptchaVerifier.render();
  return signInWithPhoneNumber(auth, number, recaptchaVerifier);
};

function AuthPage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firebaseRes, setFirebaseRes] = useState(null);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const sendOtp = async (phoneNumber) => {
    setError("");
    if (phoneNumber === "" || phoneNumber.length < 10) {
      setError("Please enter a valid phone number");
      return;
    }

    phoneNumber = "+91" + phoneNumber;
    try {
      setLoading(true);
      const res = await setUpRecaptcha(phoneNumber);
      setPhoneNumber("");
      setOtp("");
      setOtpSent(true);
      setFirebaseRes(res);
      setLoading(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const verifyOtp = async (otp) => {
    setLoading(true);
    setError("");
    try {
      await firebaseRes.confirm(otp);
      navigate("/", { replace: true });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      {otpSent ? (
        <div className="centerModel">
          <div className="heading">Enter OTP</div>
          <input
            className="input"
            value={otp}
            onChange={(e) => {
              setError("");
              setOtp(e.target.value);
            }}
          />
          <button
            className="submitButton"
            onClick={() => verifyOtp(otp)}
            disabled={loading}
          >
            {loading ? "Loading..." : "Verify"}
          </button>
        </div>
      ) : (
        <div className="centerModel">
          <div className="heading">Enter Phone Number</div>
          <input
            className="input"
            value={phoneNumber}
            onChange={(e) => {
              setError("");
              setPhoneNumber(e.target.value);
            }}
          />
          <button
            className="submitButton"
            onClick={() => sendOtp(phoneNumber)}
            disabled={loading}
          >
            {loading ? "Loading..." : "Send OTP"}
          </button>
          <div id="recaptcha-container" />
        </div>
      )}
      <div>{error}</div>
    </div>
  );
}

export default AuthPage;
