export const hubs = [
  {
    ids: ["ajmer"],
    label: "Ajmer",
  },
  { ids: ["anantapur"], label: "Anantapur" },
  { ids: ["bagewadi"], label: "Bagewadi" },
  { ids: ["bangalore"], label: "Bangalore" },
  { ids: ["chennai"], label: "Chennai" },
  { ids: ["hyderabad", "ziaguda", "chengicherla"], label: "Hyderabad" },
  { ids: ["shahpur"], label: "Shahpur" },
  { ids: ["siddipet"], label: "Siddipet" },
];

export const hyderabadHubs = [
  {
    ids: ["ziaguda"],
    label: "Ziaguda",
  },
  { ids: ["chengicherla"], label: "Chengicherla" },
];

export const baseQueryUrl =
  // "https://script.google.com/macros/s/AKfycbwXrPgb3ciC_lDiDApHB9xgxBLAphZEe0WduHdJ2ug-RInR7DIONsmLSh9rrw6yU79i/exec"
  "https://script.google.com/macros/s/AKfycbyPvJYYT_httYcdVoDl3hF0u_F8F4HV_63LA8AlfglTad6IxSa9OEGHRxGZvOjPe_2A/exec"